import { createSlice } from "@reduxjs/toolkit";
import storage from "../../services/storage";
const id = storage.get("digitProductID");
const giftCard = storage.get("digitProductType");
const getSuccessData = createSlice({
  name: "getSuccessData",
  initialState: {
    data: {}, // Initial data of your stored data
    digitProductID: id || "",
    digitProductType: giftCard || "",
    lastPurchased: {},
  },
  reducers: {
    successData: (state, action) => {
      state.data = action.payload; // Update the value in the store
    },
    // setting digital product id while setting after digital produt success
    setRecentlyBuyDigitProductId: (state, action) => {
      state.digitProductID = action.payload;
      storage.store("digitProductID", action.payload);
    },
    setRecentlyBuyDigitProductType: (state, action) => {
      state.digitProductType = action.payload;
      storage.store("digitProductType", action.payload);
    },
    getLastPurchased: (state, action) => {
      state.lastPurchased = action.payload;
    },
  },
});

export const {
  successData,
  setRecentlyBuyDigitProductId,
  setRecentlyBuyDigitProductType,
  getLastPurchased,
} = getSuccessData.actions;
export default getSuccessData.reducer;
