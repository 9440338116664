import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  products: [],
  singleProdutPackages: [],
  giftCards: [],
  isSoldProducts: [],
};
const productListing = createSlice({
  name: "productListingResp",
  initialState,
  reducers: {
    getProductListing: (state, action) => {
      const products = action.payload;
      const filterSoldProducts = products.filter((item) => {
        return (state.products = item.isAvailable === true);
      });
      const filterNotSold = products.filter((item) => {
        return (state.isSoldProducts = item.isAvailable === false);
      });
      state.products = [...filterSoldProducts, ...filterNotSold];
    },
    getSingleProdutPackages: (state, action) => {
      state.singleProdutPackages = action.payload;
    },
    getGiftsCards: (state, action) => {
      state.giftCards = action.payload;
    },
  },
});
export const { getProductListing, getSingleProdutPackages, getGiftsCards } =
  productListing.actions;
export default productListing.reducer;
