import { createSlice } from "@reduxjs/toolkit";
import storage from "../../services/storage";

const jumio = storage.get("jumio") || {};
const initialState = {
  data: {},
  fbData: {},
  jumio,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    setJumio: (state, action) => {
      const jumio = action.payload;
      state.jumio = jumio;
      storage.store("jumio", jumio);
    },
    updateUser: (state, action) => {
      state.data = action.payload;
    },
    resetUser: (state, action) => {
      state = initialState;
    },
    setFbUser: (state, action) => {
      state.fbData = action.payload;
    },
  },
});

export const { setUser, updateUser, resetUser, setJumio, setFbUser } =
  slice.actions;
export default slice.reducer;
