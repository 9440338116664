import { createSlice } from "@reduxjs/toolkit";

const digitalProductAPI = createSlice({
  name: "digitalProductAPI",
  initialState: {
    data: {}, // Initial data of your stored data
  },
  reducers: {
    digitalProductResp: (state, action) => {
      state.data = action.payload; // Update the value in the store
    },
  },
});

export const { digitalProductResp } = digitalProductAPI.actions;
export default digitalProductAPI.reducer;
