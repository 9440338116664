import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import { ToastContainer } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById("root"));
const env = process.env.REACT_APP_ENV;
// console.log({ env });
const app = (
  <>
    <App />
    <ToastContainer position="top-right" theme="dark" />
  </>
);
root.render(
  <>
  <App />
  <ToastContainer position="top-right" theme="dark" />
  </>
  // env === "production" ? <>{app}</> : <React.StrictMode>{app}</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
