// features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: [],
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      if (action.payload) state.isLoading.push(true);
      else state.isLoading.pop();
    },
  },
});

export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
