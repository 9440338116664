import React from "react";
import logo from "../../../assets/images/headercreationlogo.png";

export default function Loader() {
  return (
    <div>
      <div className="loader-main-wrapper">
        <img src={logo} alt="loader-img" />
      </div>
    </div>
  );
}
