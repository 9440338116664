import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderDetails: [],
};

const userOrderDetails = createSlice({
  name: "orderDetails",
  initialState,
  reducers: {
    setOrderDetails: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setOrderDetails } = userOrderDetails.actions;
export default userOrderDetails.reducer;
