import { createSlice } from "@reduxjs/toolkit";

const getAllMyPurchases = createSlice({
  name: "myPurchases",
  initialState: {
    data: [], // Initial data of your stored data
    secKeyData: {},
    test: [],
  },
  reducers: {
    setMyPurchases: (state, action) => {
      state.data = action.payload; // Update the value in the store
    },
    setSecKey: (state, action) => {
      state.secKeyData = action.payload;
    },
    setTest: (state, action) => {
      state.test = action.payload; // Update the value in the store
    },
  },
});

export const { setMyPurchases, setSecKey, setTest } = getAllMyPurchases.actions;
export default getAllMyPurchases.reducer;
