import { createSlice } from "@reduxjs/toolkit";

const packageName = createSlice({
  name: "packageName",
  initialState: {
    package: "", // Initial value of your stored data
  },
  reducers: {
    getPackageName: (state, action) => {
      state.package = action.payload; // Update the value in the store
    },
  },
});

export const { getPackageName } = packageName.actions;
export default packageName.reducer;
